.sync-now {
	&__wrapper {
		display: flex;
		align-items: flex-end;
		flex-direction: column;
		justify-content: center;
	}
	&__text {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 7px;
		cursor: pointer;
		margin-bottom: 0.4rem;
		&__mb {
			margin-bottom: 1rem;
		}
		& > p {
			font-size: 14px;
			color: #344735;
			white-space: nowrap;
		}
	}
	&__last-sync {
		& > p {
			color: #999999;
			font-size: 11px;
		}
	}
}
