.mapping-box {
	border-bottom: 1px solid #d4d7dc;
	&.remove-last {
		&:last-child {
			border-bottom: 0px !important;
		}
	}
	&__wrapper {
		padding: 4rem 2rem;
	}
	&__top {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 2.8rem;
		gap: 4rem;
		&--title-wrapper {
			flex: 1;
		}
		&--title {
			font-size: 1.8rem;
			font-weight: 500;
			display: inline-block;
		}

		&--title-qb {
			flex: 1;
			&-tooltip {
				display: flex;
				gap: 2rem;
				&:hover {
					cursor: pointer;
				}
			}
		}
		&--title-cap {
			cursor: pointer;
		}
	}
	&__center {
		&--wrapper {
			margin-bottom: 2.6rem;
		}
		&--single-mapping {
			display: flex;
			align-items: center;
			gap: 4rem;
			&--svg-wrapper {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex: 1;
				.field-label {
					width: 100%;
					display: flex;
					align-items: center;
					gap: 4px;
					& > h4 {
						font-size: 1.6rem;
						font-weight: 400;
					}
				}
			}
			&--select-wrapper {
				& > div {
					width: 100%;
				}
			}
			& > div {
				flex: 1;
			}
		}
	}
	&__bottom {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		& > div {
			gap: 10px;
			display: flex;
			align-items: center;
			cursor: pointer;
			& > p {
				color: #153fcd;
				font-size: 14px;
				border-bottom: 1px solid #153fcd;
				padding-bottom: 1px;
			}
		}
	}
}

.edit-delete-wrapper {
	display: flex;
	align-items: center;
	gap: 4px;
}

.form-input {
	margin-top: 3rem;
	margin-bottom: 2rem;
}

.buttons {
	display: flex;
	padding: 2rem;
}

.cancel {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 3.9rem !important;
	width: 13rem !important;
	font-size: 1.7rem;
	border: none;
	border-radius: 2.1rem;
	&:hover {
		cursor: pointer;
	}
}

.save {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 4.2rem !important;
	width: 13rem !important;
	font-size: 1.7rem;
	color: white;
	border: 1px solid #344735;
	background-color: #344735;
	border-radius: 2.1rem;
	&:hover {
		cursor: pointer;
	}
}

.qbo-modal {
	&__header {
		padding: 2rem !important;
		display: flex;
		gap: 1.1rem;
		align-items: center;
		width: 100%;
		justify-content: space-between;

		&-close {
			border: none !important;

			&:hover {
				cursor: pointer;
			}
		}
	}
}
