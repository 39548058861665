.table-action-header {
	margin-bottom: 18px;
	height: 41px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	&__wrapper {
		width: 100%;
		display: flex;
	}
	&__title {
		h3 {
			font-size: 2.4rem;
			font-weight: 700;
		}
	}
	&__actions {
	}
}
