.dynamic-table {
	overflow: auto;
	background-color: #fff !important;
	border-radius: 10px;

	&__pagination {
		justify-content: center !important;
		.ant-pagination-item {
			border-radius: 50%;
		}
		.ant-pagination-item-active {
			border-color: #000;
			background-color: #000;
			&:hover {
				border: none !important;
				border-width: 0px !important;
			}
			a {
				color: #fff !important;
			}
		}
	}
	.table-edit-icon,
	.table-delete-icon {
		cursor: pointer;
	}
}

.timesheet-status {
	display: flex;
	gap: 0.9rem;
	align-items: center;
	font-size: 1.6rem;
}

.timesheet-status-link {
	display: flex;
}

.timesheet-status-link:hover {
	cursor: pointer;
}

.timesheet-status-draft {
	color: #ff5f15;
}

.timesheet-status-published {
	color: #009049;
}
