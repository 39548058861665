.preview-container {
	width: 100vw !important;
	height: 100vh !important;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;

	.ant-modal {
		&-header {
			background-color: #f5ede2;
			padding-bottom: 1rem;
		}

		&-content {
			height: 100vh !important;
			width: 100vw !important;
			overflow-y: scroll !important;
			border-radius: 0 !important;
			background-color: #f5ede2;
			padding: 2rem 3rem !important;
		}
	}
	.ant-picker {
		width: 20rem;
		height: 4rem;
		border-radius: 0.6rem;
		border: 0.1px solid #d4d7dc;
	}

	.ant-input-number {
		width: 20rem;
		height: 4rem;
		border-radius: 0.6rem;
		border: 0.1px solid #d4d7dc;
		display: flex;
		align-items: center;
	}

	.ant-table-thead tr th {
		background-color: #eff0ef !important;
		border-top: 1px solid #c2c2c2;
		border-bottom: 1px solid #c2c2c2;
		border-radius: 0px !important;
	}

	.journal-dashed-border {
		border-right: 1px dashed #c2c2c2;
	}

	.journal-total-row td {
		border-top: 2px solid #666666 !important;
		border-bottom: 2px solid #666666 !important;
		border-right: none !important;
		background-color: #f7f7f7 !important;
		font-weight: 600;
	}

	.credit-font {
		font-weight: 600;
	}
}
