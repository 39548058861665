.dynamic-table {
	&__customer {
		color: #2c9f1c !important;

		&-name {
			margin-right: 1rem !important;
			&-inactive {
				color: red !important;
			}
		}

		&-payroll {
			padding-right: 20px !important;
		}
	}
}

.payroll {
	padding-right: 120px !important;
}
