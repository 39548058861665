.qbo-account-form {

	padding: 2rem;

	label {
		font-size: 1.6rem !important;
	}

	.ant-form-item-label {
		text-align: start;
	}

	.ant-form-item {
		margin-bottom: 2rem !important;
	}

	.ant-select {
		width: 100% !important;
	}

	&__field {
		width: 100%;
		&-input {
			width: 100%;
		}
	}
}

.qbo-account-modal {
	.ant-modal-content {
		padding: 0rem !important;
	}
}
