.dynamic-table {
	&__amount {
		margin-right: 2rem;
	}

	&__journalNo {
		color: #2c9f1c !important;
		text-decoration: none !important;
		display: flex;
		align-items: center;
		justify-content: start;
		gap: 1rem;
		font-weight: 400;
	}

	&__journal-status {
		display: flex;
		gap: 0.9rem;
		align-items: center;
		font-size: 1.6rem;

		&-draft {
			color: #ff5f15;
		}

		&-published {
			color: #009049;
		}

		&-link {
			display: flex;

			&:hover {
				cursor: pointer;
			}
		}
	}

	&__journal-actions {
		display: flex;
		&-edit {
			display: flex;
			border-right: 2px solid #000000;
			padding-right: 1rem;
			&:hover {
				cursor: pointer;
			}
		}

		&-preview {
			display: flex;
			padding-left: 1rem;
			&:hover {
				cursor: pointer;
			}
		}
	}
}
