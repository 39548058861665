.registration {
	height: 100vh;
	&__wrapper {
		height: 100%;
	}
	&__details {
		max-width: 57.4rem;
		// padding-top: 12.8rem;
		margin: 0 auto;
		&--logo {
			height: 7.4rem;
			width: 35.1rem;
			margin-bottom: 1rem;
			& > img {
				height: 100%;
				width: 100%;
			}
		}
	}
	&__layout {
		background-color: #e7d1b7;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: right;
		max-width: 70rem !important;
		&--image {
			max-height: 100vh !important;
			width: 108rem !important;
			// object-fit: contain;
		}
	}
}
