.subscription {
    padding: 30px;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    height: calc(100vh - 200px);
    overflow: auto;

    &__text {
        font-size: 22px;
        font-weight: 500;
    }

    &__main-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #344735;
        padding-bottom: 15px;
        margin-bottom: 20px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--heading {
            font-size: 22px;
            font-weight: 500;
        }

        &--action {

            &-button {
                background-color: black;
                color: white;
                border-radius: 21px;
                font-size: 14px;
                font-weight: 600;
                height: 36px;
                border-color: black;
            }

            &-button:hover {
                background-color: black !important;
                color: white !important;
                border-radius: 21px;
                font-size: 14px;
                font-weight: 600;
                height: 36px;
                border-color: black !important;
            }

        }

    }

    &__content {

        margin-top: 20px;

        &--box {
            border: 1px solid #D4D7DC;
            border-radius: 8px;
            height: 100%;

            &-header {
                background-color: #344735;
                padding: 20px;
                color: #EFF0EF;
                font-size: 18px;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                border-bottom: 1px solid #D4D7DC;
            }

            &-content {
                padding: 20px;

                ul {
                    padding-left: 20px;
                }

                ul li {
                    padding-bottom: 10px;
                    list-style: circle;
                    font-size: 16px;
                    font-weight: 400;
                }

                &-pricing {
                    font-size: 30px;
                    font-weight: 500;
                }

                &-description {
                    font-size: 14px;
                    font-weight: 400;
                }
            }

        }
    }

}