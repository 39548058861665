.cost-allocation-main {
	&__header {
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 10px;
	}

	&__content {
		&--row {
			justify-content: space-between;
			margin-bottom: 15px;

			&--title {
				font-size: 16px;
				font-weight: 400;
				display: flex;
				justify-content: center;
			}

			&--link {
				display: flex;
				flex-direction: row;
				min-width: 12rem;
				justify-content: space-between;
			}

			&--value {
				color: #1543cc;
				font-size: 16px;
				font-weight: 400;
				text-decoration: underline;

				:hover {
					color: #1543cc;
					text-decoration: underline;
				}
			}
		}
	}
}
.cost-allocation-main__content--row--title {
	min-width: 6rem !important;
}

.begin-cost-allocation-button {
	background-color: black;
	color: white;
	border: none;
	padding: 0.5rem 1.5rem;
	border-radius: 2rem;
	text-decoration: none;

	&:hover {
		cursor: pointer;
	}

	&-col {
		min-width: 8rem;
	}
}
