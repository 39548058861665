.get__support-modal {
	&-header {
		justify-content: space-between;
		padding: 2rem;
		font-size: 2rem;
	}

	&-body {
		padding: 2rem 3rem;
		p {
			font-size: 1.4rem;
			font-weight: 500;
		}
	}

	&-close {
		:hover {
			cursor: pointer;
		}
	}

	&-footer {
		padding: 2rem 3rem !important;

		&-save {
			display: flex;
			align-items: center;
			justify-content: center;
			background: #000;
			font-size: 1.6rem;
			color: #fff;
			font-weight: 600;
			border-radius: 1000px !important;
			border: none;
			cursor: pointer;
			min-height: 5.2rem;
			min-width: 12rem;

			&:hover {
				color: #fff !important;
				background: #000 !important;
			}
		}

		&-cancel {
			display: flex;
			align-items: center;
			justify-content: center;
			background: #fff;
			border: 2px solid #e3e4e7;
			box-shadow: none;
			font-size: 1.6rem;
			color: #a2a7ad;
			font-weight: 600;
			border-radius: 1000px !important;
			cursor: pointer;
			width: 13rem;
			min-height: 5.2rem;
			min-width: 12rem;

			&:hover {
				border-color: #e3e4e7 !important;
				background: #fff !important;
				color: #a2a7ad !important;
			}
		}
	}
}
