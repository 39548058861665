*,
::after,
::before {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
	font-family: 'Lexend', sans-serif !important;
}

html {
	font-size: 10px;
}

body:has(.stop-scroll-x) {
	overflow: hidden;
}

body {
	font-family: 'Lexend', sans-serif;
}

/* Removed scrollbar temporary */
::-webkit-scrollbar {
	/* width: 0px !important;
	display: none !important; */
}

/* Global css */
.btn-black {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #344735;
	font-size: 1.6rem;
	color: #fff;
	font-weight: 600;
	border-radius: 1000px !important;
	gap: 8px;
	padding: 1.3rem 2.8rem;
	border: none;
	cursor: pointer;
}
.btn-black:disabled {
	cursor: not-allowed;
	/* pointer-events: none; */
}
.btn-black-small {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #344735;
	font-size: 1.4rem;
	color: #fff;
	font-weight: 400;
	border-radius: 1000px !important;
	gap: 8px;
	padding: 5px 10px;
	border: none;
	cursor: pointer;
}

.error-message {
	color: #ff4d4f;
	font-size: 14px;
	/* font-weight: 500; */
}

.ant-table-column-title {
	flex: 0 0 auto !important;
}

.ant-table-column-sorters {
	justify-content: flex-start !important;
	gap: 10px;
}

.btn-black:hover {
	color: #fff !important;
}

.ant-modal-content {
	padding-top: 30px !important;
	padding-bottom: 30px !important;
}

.ant-input-affix-wrapper {
	border: 1px solid #d9d9d9 !important;
	box-shadow: none !important;
}
.ant-input-affix-wrapper:focus {
	outline: none !important;
	box-shadow: none !important;
}

.ant-switch-checked {
	background: #009049 !important;
}

.table-global thead tr th {
	font-weight: 600 !important;
	font-size: 1.6rem;
}
.table-global tbody tr {
	font-size: 1.6rem;
}

.ant-table-cell .ant-checkbox-checked .ant-checkbox-inner {
	background-color: #009049;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
	border-color: #d4d7dc !important;
}

.ant-checkbox-checked,
.ant-checkbox-checked::after {
	border: none !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
	background-color: #009049 !important;
	border-color: #d4d7dc !important;
	outline: none !important;
}
.ant-switch-handle::after {
	content: '';
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-weight: bold;
	height: 100%;
	width: 100%;
	background-image: url('/public/assets/images/false.png');
	background-repeat: no-repeat;
	background-size: 11px;
	background-position: center center;
}

.ant-switch-checked .ant-switch-handle::after {
	content: '';
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	color: #009049;
	height: 100%;
	width: 100%;
	font-weight: bold;
	background-image: url('/public/assets/images/true.png');
	background-repeat: no-repeat;
	background-size: 11px 9px;
	background-position: center center;
}

.required-color {
	color: red;
}

.ant-upload-select:hover button {
	color: #999999 !important;
	border-color: #999999 !important; /* Optional: Change the border color on hover if needed */
	background-color: transparent !important; /* Optional: Change the background color on hover if needed */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

/* End global */
@media (max-width: 1600px) {
	html {
		font-size: 9px;
	}
}

@font-face {
	font-family: 'Lexend';
	src: url('/public/assets/fonts/Lexend-Regular.woff') format('woff');
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: 'Lexend';
	src: url('/public/assets/fonts/Lexend-Medium.woff') format('woff');
	font-weight: 500;
	font-display: swap;
}

@font-face {
	font-family: 'Lexend';
	src: url('/public/assets/fonts/Lexend-SemiBold.woff') format('woff');
	font-weight: 600;
	font-display: swap;
}

@font-face {
	font-family: 'Lexend';
	src: url('/public/assets/fonts/Lexend-Bold.woff') format('woff');
	font-weight: 700;
	font-display: swap;
}

/* Reusable class */

.cursor-pointer {
	cursor: pointer;
}

.flex {
	display: flex;
}
.align-center {
	align-items: center;
}
.justify-center {
	justify-content: center;
}

.bg-white {
	background-color: #fff !important;
}
.bg-white:hover {
	background-color: #fff !important;
}

.bg-split {
	background-color: #e4e4e4 !important;
}

.ant-table-cell::before,
.ant-table-cell::after {
	display: none;
}

.toast-container-div {
	width: 25px !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

.toast-container-custom svg {
	min-width: min-content !important;
	width: 100% !important;
}

.pointer-event-none {
	pointer-events: none !important;
}

.ant-select-item-option-selected {
	background-color: #efefef !important;
}

.ant-tooltip-inner {
	font-size: 11px !important;
	min-height: auto !important;
	padding: 1rem !important;
	display: inline-block !important;
	background-color: #333333 !important;
	border-radius: 6px !important;
}

.ant-input-number-handler-wrap {
	display: none !important;
}

.main-table-header-color {
	background-color: #485949;
}

.ant-input-number,
.ant-input-number:hover,
.ant-input-number:focus {
	border: 1px solid #d2d4d8 !important;
	box-shadow: none !important;
}

.required-field {
	color: red;
	font-size: 14px;
	font-weight: 500;
	display: inline-block;
}

.ant-picker-now {
	display: none !important;
}

.ant-picker-ok button {
	background: #000 !important;
}
.ant-picker-ok button span {
	color: #fff !important;
}

.ant-picker-large:not(.ant-picker-status-error) {
	border: 1px solid #d2d4d8 !important;
	box-shadow: none !important;
}

.sync-loader-gif {
	max-height: 2.4rem;
}

.ant-picker:hover,
.ant-picker:focus,
.ant-picker-focused {
	outline: none !important;
	border-color: #d9d9d9;
	box-shadow: none !important;
}

.tick-small {
	enable-background: new 0 0 17 18;
}

.bg-f6f6f6 {
	background: #f6f6f6 !important;
}

.ant-tabs-nav {
	height: 68px !important;
	margin-bottom: 0 !important;
}

.ant-tooltip {
	z-index: 9999;
}

/* .ant-table-body {
	overflow-y: auto !important;
} */

.ant-select-dropdown {
	/* width: auto !important; */
	z-index: 99999 !important;
}

.ant-picker-dropdown {
	z-index: 99999 !important;
}

/* .ant-tree-select-dropdown {
	width: auto !important;
} */

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	/* background: rgb(52, 71, 53); */
	background: #888;
	border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.text-danger {
	color: red;
}

.ant-modal-mask {
	z-index: 9999 !important;
}

/* :where(.css-dev-only-do-not-override-1m62vyb).ant-checkbox-indeterminate
	.ant-checkbox-inner:after {
	background-color: transparent;
} */

.ant-checkbox-checked .ant-checkbox-inner {
	border: none;
}

.ant-table-selection-column {
	background-color: white !important;
}

.ant-table-row-expand-icon-cell {
	background-color: white !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
	background: none !important;
}

.ant-steps
	.ant-steps-item-finish
	> .ant-steps-item-container
	> .ant-steps-item-content
	> .ant-steps-item-title::after {
	background-color: #344735 !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
	color: #344735;
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
	background-color: #eeeeee;
	border-color: #eeeeee;
}

.ant-upload-wrapper .ant-upload-drag p.ant-upload-drag-icon .anticon {
	color: #344735;
	font-size: 55px;
}

.ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover {
	border-color: #344735;
}

.ant-checkbox-checked .ant-checkbox-inner {
	background-color: #009049;
}

.custom-select .ant-select-selector {
	border: 1px solid transparent !important; /* Remove border */
	box-shadow: none !important; /* Remove any shadow */
	background-color: #fff !important; /* Set background color to white */
	border-radius: 4px !important; /* Maintain the border radius */
	padding: 0; /* Adjust padding if needed */
	height: 100px; /* Keep the height consistent */
}

.custom-select.ant-select .ant-select-selector:focus {
	border-color: transparent !important; /* Prevent border on focus */
	box-shadow: none !important; /* Prevent shadow on focus */
}

.custom-select.ant-select .ant-select-selector:hover {
	border-color: transparent !important; /* Prevent border on hover */
}

.custom-card.ant-card .ant-card-head {
	background-color: #344735;
	padding: 1px !important;
}
