.sidebar {
	.sidebar__wrapper {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;
	}
	&__support {
		padding: 0 0 3.8rem 1.8rem;
		& > button {
			font-size: 12px !important;
			border: 2px solid #e7d1b7 !important;
			color: #e7d1b7 !important;
		}
	}
}
