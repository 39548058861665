.preview-container {
	&__top-close {
		&:hover {
			cursor: pointer;
		}
	}
	&__top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 2.3rem;

		&-title {
			font-weight: 600;
			font-size: 2.4rem;
		}
	}

	&__content {
		background-color: #ffffff;
		border-radius: 0.8rem;

		&--header {
			display: flex;
			padding: 4rem 3rem;
			gap: 11rem;

			&-title {
				font-weight: 500 !important;
				font-size: 2rem;
			}

			&-date {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 1rem;
				font-size: 1.6rem;
				font-weight: 450;
			}

			&-number {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 1rem;
				font-size: 1.6rem;
				font-weight: 450;
			}
		}

		&--footer {
			padding: 3.8rem;

			&-textarea {
				margin-top: 1.6rem !important;
				margin-bottom: 3.6rem !important;
				height: 8rem;
			}

			&-upload {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: start;

				& p {
					font-weight: 500;
				}
			}

			&-notes {
				& label {
					font-weight: 500;
				}
			}

			&-buttons {
				display: flex;
				gap: 2rem;

				&-button {
					width: 16rem;
					height: 4.2rem;
					border-radius: 4rem;
					border: 0;
					background-color: #344735;
					color: #fff;
					&:hover {
						cursor: pointer;
					}
				}
				&-cancel {
					width: 12rem;
					height: 4.2rem;
					border-radius: 4rem;
					border: 0rem;
					background-color: #e3e4e7;
					color: #a2a7ad;
					&:hover {
						cursor: pointer;
					}
				}
			}
		}
	}
}
