.reports-container {
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 2rem;

		&--title {
			font-weight: 700;
			font-size: 2.4rem;
		}

		&--actions {
			display: flex;
			&-layoutExpand {
				display: flex;
				align-items: center;
				border: none;
				background-color: transparent;
				color: black;
				padding: 10px;
				border-radius: 17px;
				cursor: pointer;
				outline: none;
			}

			&-preview {
				width: 20rem;
				height: 4.2rem;
				background-color: #000000;
				color: #ffffff;
				border: 2px solid #000000;
				border-radius: 5rem;
				font-size: 1.5rem;
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
}

.reports-main {
	background-color: #ffffff;
}
