.dynamic-table {
	overflow: auto;
	background-color: #fff !important;
	border-radius: 10px !important;

	&__pagination {
		justify-content: center !important;
		.ant-pagination-item {
			border-radius: 50%;
		}
		.ant-pagination-item-active {
			border-color: #000;
			background-color: #000;
			&:hover {
				border: none !important;
				border-width: 0px !important;
			}
			a {
				color: #fff !important;
			}
		}
	}
	.table-edit-icon,
	.table-delete-icon {
		cursor: pointer;
	}
}

.user_invite_button {
	background-color: black;
	color: white;
	border: none;
	padding: 0.3rem 2rem;
	border-radius: 5rem;
	min-width: 8rem;
	height: 4rem;
	&:hover {
		cursor: pointer;
	}
}

.invite-style {
	display: flex;
	justify-content: center;
	align-items: center;
	&:hover {
		cursor: pointer;
	}
}
