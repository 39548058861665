.global-layout {
	height: 100vh;

	&__body {
		display: flex;

		// &--body {
		// 	background: #f8f1e9;
		// 	padding: 2.1rem 3rem 3.6rem 3rem;
		// 	position: relative !important;
		// }

		&--body-widthFull {
			width: 100%;
			background: #f8f1e9;
			padding: 2.1rem 3rem 3.6rem 3rem;
			position: relative !important;
			height: calc(100vh - 70px);
			overflow-y: scroll;
		}

		&--body-widthFixed {
			width: calc(100vw - 200px) !important;
			background: #f8f1e9;
			padding: 2.1rem 3rem 3.6rem 3rem;
			position: relative !important;
			height: calc(100vh - 70px);
			overflow-y: auto;
		}
	}

	&__body--sidebar-show {
		display: block;
	}

	&__body--sidebar-hide {
		display: none;
	}
}

.spin-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.9); /* Optional: slight overlay */
}

.spin-message {
	margin-top: 16px;
	font-size: 16px;
	padding-left: 15px;
	color: #344735;
	text-align: center;
}
