.time-sheet-validate-modal .ant-modal-body {
	padding: 3rem;

	& p {
		font-size: 2rem;
	}

	.save {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #000;
		font-size: 1.6rem;
		color: #fff;
		font-weight: 600;
		border-radius: 1000px !important;
		border: none;
		cursor: pointer;
		min-height: 5.2rem;
		min-width: 13rem;

		&:hover {
			color: #fff !important;
			background: #000 !important;
		}
	}

	.cancel {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #fff;
		border: 2px solid #e3e4e7;
		box-shadow: none;
		font-size: 1.6rem;
		color: #a2a7ad;
		font-weight: 600;
		border-radius: 1000px !important;
		cursor: pointer;
		width: 13rem;
		min-height: 5.2rem;

		&:hover {
			border-color: #e3e4e7 !important;
			background: #fff !important;
			color: #a2a7ad !important;
		}
	}
}

.warning-container {
	max-height: 500px;
	overflow-y: auto;
}