.dashboard {
	padding-top: 2rem !important;
	.ant-space-item {
		background-color: white !important;
	}

	// .ant-picker {
	// 	border: 1px solid #d2d4d8 !important;
	// }

	// .ant-select-selector {
	// 	border-radius: 0.8rem !important;
	// }
}
