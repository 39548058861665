.customRow {
	padding: 35px 0px 0px 0px !important;
}

.customButton {
	margin: 10px 0px 0px 0px;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
	background-color: #344735 !important;
	border-color: #344735 !important;
}
