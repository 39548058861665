.employee-container {
	&__title {
		font-weight: 700;
		font-size: 2.4rem;
		margin-bottom: 2rem;
	}
}

.search-filter {
	padding: 2rem !important;
}

.table-employee thead th {
	background: #485949 !important;
	color: #fff !important;
	border-radius: 0px !important;
	padding: 1.4rem !important;
}

.table-employee thead th:hover {
	background: #485949 !important;
	color: #fff !important;
	cursor: pointer;
}

.table-employee {
	background-color: #fff !important;
	border-radius: 1rem !important;
}

.clock {
	display: flex !important;
}

.dynamic-table {
	&__employee {
		color: #2c9f1c !important;
		// text-decoration: none !important;

		&-name {
			margin-right: 1rem !important;
		}
	}
}

.search-filter-second {
	display: flex !important;
	align-items: center !important;
}

.search-filter-main {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center !important;

	&-picker {
		display: flex;
		justify-content: space-between;
		width: 100%;
		&-left {
			display: flex;
			align-items: center;
		}
		&-right {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 2rem;
			gap: 2rem;
		}
	}

	&-date {
		display: flex;
		align-items: center;
	}

	&-pay-period {
		display: flex;
		margin-right: 1.2rem;
	}

	&-prev {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 1.4rem;
		gap: 0.7rem;

		&:hover {
			cursor: pointer;
		}
	}

	&-next {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 1.4rem;
		gap: 0.7rem;

		&:hover {
			cursor: pointer;
		}
	}
}

.buttons {
	display: flex;
	align-items: center;
	gap: 2rem;
	padding-left: 3.336rem;
	padding-right: 3.336rem;

	&--btn {
		border: none;
		font-size: 1.8rem;
		padding: 1.8rem 4rem;
		border-radius: 5000px;
		cursor: pointer;
		font-weight: 600;
	}

	&--save {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #000;
		font-size: 1.6rem;
		color: #fff;
		font-weight: 600;
		border-radius: 1000px !important;
		border: none;
		cursor: pointer;
		width: 13rem;
		min-height: 4.5rem;
		margin-left: 2rem;
		&:hover {
			background: #000 !important;
		}
	}

	&--cancel {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #fff;
		border: 2px solid #e3e4e7;
		box-shadow: none;
		font-size: 1.6rem;
		color: #a2a7ad;
		font-weight: 600;
		border-radius: 1000px !important;
		cursor: pointer;
		width: 13rem;
		min-height: 5.2rem;

		&:hover {
			background: #fff !important;
			color: #a2a7ad !important;
		}
	}
}

.pay-period-filter {
	width: 250px !important;
}
