.super-admin__table {
	thead th {
		border-radius: 0px !important;
	}

	&-actions {
		display: flex;
		padding-left: 1rem;
		// align-items: center;
		:hover {
			cursor: pointer;
		}
	}
}
