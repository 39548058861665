.employee-container {
	&__title {
		font-weight: 700;
		font-size: 2.4rem;
		margin-bottom: 2rem;
	}
}

.search-filter {
	padding: 2rem !important;
}

.table-employee thead th {
	background: #485949 !important;
	color: #fff !important;
	border-radius: 0px !important;
	padding: 1.4rem !important;
}

.table-employee thead th:hover {
	background: #485949 !important;
	color: #fff !important;
	cursor: pointer;
}

.table-employee {
	background-color: #fff !important;
	border-radius: 1rem !important;
}

.clock {
	display: flex !important;
}

.dynamic-table {
	&-total {
		// text-align: center !important;
		font-weight: 600;
	}
	&__employee {
		color: #2c9f1c !important;
		// text-decoration: none !important;

		&-name {
			margin-right: 1rem !important;
			&-inactive {
				color: red !important;
			}
		}
	}

	&__status {
		display: flex;
		align-items: center;
		gap: 0.7rem;
	}

	&__calculator {
		display: flex;
		align-items: center;
		&:hover {
			cursor: pointer !important;
		}
	}
}