.range-picker {
	// background-color: red !important;
	border: 1px solid #d9d9d9 !important;
	box-shadow: none !important;
	.ant-picker-active-bar {
		background-color: #d9d9d9 !important;
	}
}
.ant-picker-cell-selected {
	.ant-picker-cell-inner {
		background-color: #27272e !important;
		color: #fff !important;
	}
}

.ant-picker-cell-range-start-single {
	.ant-picker-cell-inner {
		background-color: #27272e !important;
	}
}
.ant-picker-cell-range-start {
	.ant-picker-cell-inner {
		background-color: #27272e !important;
	}
}
.ant-picker-cell-range-end {
	.ant-picker-cell-inner {
		background-color: #27272e !important;
	}
}
.ant-picker-cell-inner {
	&::before {
		border: 1px solid #27272e !important;
	}
}
.ant-picker-cell-in-range {
	&::before {
		// background: #d4d7dc !important;
	}
}
// .ant-picker-cell-in-range-hover {
// 	background: red !important;
// }
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
		.ant-picker-cell-range-start-single
	)::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
		.ant-picker-cell-range-end-single
	)::before,
.ant-picker-time-panel-column
	> li.ant-picker-time-panel-cell-selected
	.ant-picker-time-panel-cell-inner {
	background: #d4d7dc !important;
}

.ant-picker-range-hover-start {
	&::after {
		background: #bfbfc0 !important;
	}
}

.ant-picker-cell-inner {
	&::after {
		background: #bfbfc0 !important;
	}
}

.ant-select-tree-node-selected {
	background: rgb(239, 239, 239) !important;
}
.time-sheet-modal__body .ant-btn:hover {
	border-color: black !important;
	color: black !important;
}

.time_sheet_modal_footer .ant-btn:hover {
	border-color: black !important;
	color: white !important;
}

.time-sheet-modal__body {
	padding: 1rem 2rem !important;
}

.employee-list-modal {
	&:hover {
		.ant-checkbox-checked {
			.ant-checkbox-inner {
				border: none !important;
				background: #333333 !important;

				&:hover {
					background: #333333 !important;
				}
			}
		}
	}

	.ant-checkbox-checked {
		.ant-checkbox-inner {
			border: none !important;
			background: #333333 !important;

			&:hover {
				background: #333333 !important;
			}
		}
	}
}
