.confirm-delete-model__button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
	margin-top: 3.2rem;
	&--btn {
		border: none;
		font-size: 1.8rem;
		padding: 1.3rem 3rem;
		border-radius: 500px;
		cursor: pointer;
		font-weight: 600;
	}
	&--save {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #000;
		font-size: 1.6rem;
		color: #fff;
		font-weight: 600;
		border-radius: 1000px !important;
		gap: 8px;
		padding: 1.3rem 2.8rem;
		border: none;
		cursor: pointer;
	}
	&--cancel {
		background: #fff;
		color: #000;
		border: 1px solid #000;
	}
}

.customCell {
	display: flex !important;
	justify-content: center !important;
}

.tab-content {
	max-height: 33vh;
	overflow-y: auto; /* Enable vertical scrolling within the content */
	padding: 10px; /* Optional: Add some padding inside each TabPane */
	box-sizing: border-box; /* Ensure padding is included in height calculation */
}

// .custom-form {
// 	max-height: 60vh;
// 	overflow-y: auto;
// }

.configuration-settings--logos {
	margin-top: 12px;
}

.qbo-modal {
	&__header {
		padding: 2rem !important;
		display: flex;
		gap: 1.1rem;
		align-items: center;
		width: 100%;
		justify-content: space-between;

		&-close {
			border: none !important;

			&:hover {
				cursor: pointer;
			}
		}
	}
}

.form-input {
	margin-top: 3rem;
	margin-bottom: 2rem;
}

.buttons {
	display: flex;
	padding: 2rem;
}

.cancel {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 3.9rem !important;
	width: 13rem !important;
	font-size: 1.7rem;
	border: none;
	border-radius: 2.1rem;
	&:hover {
		cursor: pointer;
	}
}

.save {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 4.2rem !important;
	width: 13rem !important;
	font-size: 1.7rem;
	color: white;
	border: 1px solid #344735;
	background-color: #344735;
	border-radius: 2.1rem;

	&:hover {
		cursor: pointer;
		background-color: #344735;
	}
}
