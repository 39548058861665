	.custom-rules-container {
	background-color: white;
}

.add-rule-button {
	background-color: black;
	color: white;
	border-radius: 5rem;
	border: none;
	outline: none;
	min-width: 12rem;
	height: 3.5rem;

	&:hover {
		cursor: pointer;
	}
}
