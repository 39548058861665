.time-sheet-modal {
	&__header {
		padding: 1.1rem 3rem !important;
		display: flex;
		gap: 1.1rem;
		align-items: center;
		width: 100%;
		min-height: 9.6rem !important;
		justify-content: space-between;

		&--left {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 2.7rem;
		}

		&--avatar {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 7rem;
			height: 7rem;
			img {
				width: 7rem;
				height: 7rem;
				border-radius: 3.4rem;
				object-fit: cover !important;
				object-position: top !important;
			}
		}
		&--upload {
			button {
				display: flex;
				justify-content: center;
				align-items: center;
			}
			&--text {
				color: #999999;
			}
		}
		&-delete {
			border: none !important;
			&:hover {
				cursor: pointer;
			}
		}
	}

	&__body {
		&__buttons {
			gap: 4rem !important;
		}

		&__button {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 1rem;
			height: 3.9rem !important;
			width: 13rem !important;
			font-size: 1.7rem;
			border: 1px solid black;
			border-radius: 2.1rem;
			&-inside {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 1rem;
			}
		}

		&__send-email {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 4.2rem !important;
			width: 17.4rem !important;
			font-size: 1.7rem;
			color: white;
			border: 1px solid black;
			background-color: black;
			border-radius: 2.1rem;
			&-inside {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 1rem;
			}
		}

		&__export {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 1rem;
			height: 4.2rem !important;
			width: 20rem !important;
			font-size: 1.7rem;
			color: white;
			border: 1px solid black;
			background-color: black;
			border-radius: 2.1rem;
		}
	}
}
