.modal {
	&__footer {
		display: flex;
		justify-content: center;
		align-items: center;

		&-button {
			background-color: #000;
			color: #fff;
			border-radius: 4rem;
			border: none;
			outline: none;
			padding: 1rem 0rem;
			width: 13rem;

			&:hover {
				cursor: pointer;
			}
		}
	}

	&__message {
		font-size: 2rem;
		font-weight: 600;
	}
}

.test-modal {
	background-color: red;
}
