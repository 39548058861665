.graph-main {
	&__col {
		padding: 0px !important;

		&--customer-expense {
			margin: 10px;
			background-color: #ffffff;
			border-radius: 16px;
			padding: 20px;
			max-height: 528px;
			overflow-y: auto;
		}

		&--employee-hours {
			margin: 10px;
			background-color: #ffffff;
			border-radius: 16px;
			padding: 20px;
			max-height: 528px;
			overflow-y: auto;
		}

		&--employee-expense {
			margin: 10px;
			background-color: #ffffff;
			border-radius: 16px;
			padding: 20px;
			max-height: 528px;
			overflow-y: auto;
		}

		&--cost-allocation {
			margin: 10px;
			background-color: #ffffff;
			border-radius: 16px;
			padding: 20px;
			max-height: 528px;
			overflow-y: auto;
		}
	}
}
