.graph {
	min-height: 540px;
	overflow-y: auto;
	padding: 2rem;

	&__header {
		padding: 2rem !important;
		display: flex;
		gap: 1.1rem;
		align-items: center;
		width: 100%;
		justify-content: space-between;

		&-close {
			border: none !important;

			&:hover {
				cursor: pointer;
			}
		}
	}

}

.modal__footer {
	&-button {
		background-color: #000;
		color: #fff;
		border-radius: 4rem;
		border: none;
		outline: none;
		padding: 1rem 0rem;
		width: 13rem;

		&:hover {
			cursor: pointer;
		}
	}
}